import type { AccountNav } from '~/types';

const data: AccountNav = {
    account: {
        title: 'Account',
        hideTitleInMenu: true,
        items: [
            {
                id: 'profile',
                title: 'Profile',
                value: 10,
                to: '/account/profile'
            },
            {
                id: 'addresses',
                title: 'Addresses',
                value: 20,
                to: '/account/addresses'
            },
            {
                id: 'bodies',
                title: 'Bodies',
                value: 30,
                to: '/account/bodies'
            },
            {
                id: 'vouchers',
                title: 'Vouchers',
                value: 40,
                to: '/account/vouchers'
            },
            {
                id: 'subscriptions',
                title: 'Subscriptions',
                value: 50,
                to: '/account/subscriptions'
            }
        ]
    },
    orders: {
        title: 'Orders',
        items: [
            {
                id: 'bulk-order',
                title: 'New Bulk Order',
                value: 60,
                to: '/account/bulk-order',
                permissions: {
                    page: 'bulk_order_form'
                }
            },
            {
                id: 'order-status',
                title: 'Check Order Status',
                value: 70,
                to: '/order-status',
                hideInMenu: true
            },
            {
                id: 'orders',
                title: 'Order History',
                value: 80,
                to: '/account/orders',
            },
            {
                id: 'registration',
                title: 'Product Registrations',
                value: 90,
                to: '/account/registrations'
            },
            {
                id: 'claims',
                title: 'Warranty Claims',
                value: 100,
                to: '/account/claims'
            }
        ]
    },
    support: {
        title: 'Support',
        hideInMenu: true,
        items: [
            {
                id: 'faq-main',
                title: 'Frequently Asked Questions',
                value: 110,
                to: '/faq',
                hideInMenu: true
            },
            {
                id: 'warranty-claim',
                title: 'Submit a Warranty Claim',
                value: 120,
                to: '/support/warranty',
                hideInMenu: true
            },
            {
                id: 'register-product',
                title: 'Register a Product',
                value: 130,
                to: '/support/register',
                hideInMenu: true
            },
            {
                id: 'returns',
                title: 'Returns',
                value: 140,
                to: '/support/returns',
                hideInMenu: true
            },
            {
                id: 'contact',
                title: 'Contact Us',
                value: 150,
                to: '/support/contact',
                hideInMenu: true
            }
        ]
    },
    billing: {
        title: 'Billing',
        items: [
            {
                id: 'invoices',
                title: 'Invoices',
                value: 60,
                to: '/account/invoices'
            },
            {
                id: 'payment-methods',
                title: 'Payment Methods',
                value: 170,
                to: '/account/payment-methods'
            },
            {
                id: 'pricing-terms',
                title: 'Pricing & Terms',
                value: 180,
                to: '/account/pricing-terms'
            },
            {
                id: 'balance',
                title: 'Account Balance',
                value: 190,
                to: '/account/balance'
            }
        ]
    },
    products: {
        title: 'Products',
        items: [
            {
                id: 'product-list',
                title: 'Product List',
                value: 200,
                to: '/account/product-list',
                permissions: {
                    page: 'product_list'
                }
            },
            {
                id: 'product-launches',
                title: 'New Products',
                value: 210,
                to: '/account/product-launches?assortment_type=new',
                permissions: {
                    page: 'product_launches'
                }
            },
            {
                id: 'marketing-assets',
                title: 'Marketing Assets',
                value: 220,
                to: '/account/marketing-assets?has_marketing_assets=true',
                permissions: {
                    page: 'marketing_assets'
                }
            },
            {
                id: 'sku-list',
                title: 'SKU List',
                value: 230,
                to: '/account/sku-list',
                permissions: {
                    page: 'sku_list'
                }
            },
            {
                id: 'product-catalog',
                title: 'Product Catalog',
                value: 240,
                to: '/product-catalog',
                hideInMenu: true
            },
            {
                id: 'store-locator',
                title: 'Store Locator',
                value: 250,
                to: '/retailers/stores/',
                hideInMenu: true
            }
        ]
    },
    organization: {
        title: 'Organization',
        items: [
            {
                id: 'manage-members',
                title: 'Manage Members',
                value: 250,
                to: '/account/manage-members',
                permissions: {
                    page: 'manage_members'
                }
            },
            {
                id: 'map-policy',
                title: 'MAP Policy',
                value: 260,
                to: '/account/map-policy',
                permissions: {
                    page: 'map_policy'
                }
            },
            {
                id: 'marketplace-policy',
                title: 'Marketplace Policy',
                value: 270,
                to: '/account/third-party-marketplace-policy',
                permissions: {
                    page: 'marketplace_policy'
                }
            },
            {
                id: 'faq-retailer',
                title: 'Retailer FAQs',
                value: 280,
                to: '/account/faq',
                permissions: {
                    page: 'retail_faq'
                }
            },
            {
                id: 'account-team',
                title: 'Account Team',
                value: 290,
                to: '/account/team',
                permissions: {
                    page: 'account_team'
                }
            },
            {
                id: 'agreements',
                title: 'Agreements',
                value: 50,
                to: '/account/agreements',
                permissions: {
                    page: 'agreements'
                }
            }
        ]
    }
};

export function useAccountNavData() {
    return {
        accountNavData: Object.assign({}, data)
    };
}
