import { orderBy as lo_orderBy } from 'es-toolkit';
import { isString as lo_isString } from 'es-toolkit';
import {
    useAccountNavData,
    useUserLite
} from '~/composables';
import type {
    AccountNavItem,
    AccountNavItemPermissions,
    AccountNav,
    MembershipPermissions
} from '~/types';


export const AccountMenu = () => useState<Nilish<AccountNav>>('keyAccountMenu', () => null);
export const RouteBlackList = () => useState<string[]>('keyRouteBlackList', () => []);

export function useAccountNav() {
    const $config = useRuntimeConfig();
    const {
        getCurrentMembership,
        isOrgMemberAllowed
    } = useUserLite();
    const {
        accountNavData
    } = useAccountNavData();
    const accountMenu = AccountMenu();
    const routeBlackList = RouteBlackList();
    const accountMenuGroups: AccountNav = accountNavData;

    function verifyPermission(
        rule?: Nilish<AccountNavItemPermissions>,
        mbrPerms?: Nilish<MembershipPermissions>
    ): boolean {
        if (!rule) {
            return true;
        }

        let verified = false;

        if (rule.page && isOrgMemberAllowed('page', rule.page)) {
            verified = true;
        }
        
        return verified;
    }

    function buildMenu(): AccountNav {
        const featureFlagObj = $config.public.featureFlags;
        const membershipPermissions = getCurrentMembership()?.permissions;
        const menu: AccountNav = {};

        for (const [groupKey, groupObj] of Object.entries(accountMenuGroups)) {
            const subMenu: AccountNavItem[] = [];

            groupObj.items.forEach((item: AccountNavItem) => {
                const { featureFlagKey, permissions: rule } = item;
                const isFeatureAllowed = featureFlagKey ? featureFlagObj[featureFlagKey] : true;
                const isVerified = verifyPermission(rule, membershipPermissions);
                                
                if (isFeatureAllowed && isVerified) {
                    subMenu.push(item);
                } else if (item.to) {
                    const to = lo_isString(item.to) ? item.to : item.to.path;

                    if (to) {
                        routeBlackList.value.push(to);
                    }
                }
            });

            if (subMenu.length) {
                menu[groupKey] = {
                    ...groupObj,
                    items: lo_orderBy(subMenu, ['value'], ['asc'])
                };
            }
        }

        accountMenu.value = menu;
        return menu;
    }

    return {
        accountMenu,
        buildMenu
    };
}
